import React from "react";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";


const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-component">

      <div className="container footer-wrapper">

        {/* Footer content */}
        <div className="footer-content">

          {/* Footer left */}
          <div className="footer-left">
            <StaticImage src="../assets/images/logo-white.png" className="footer-logo" alt=""/>
            <p className="footer-text">Posetite nas i prepustite se magiji našeg <b>salona lepote</b>, gde ćete
              dobiti <b>vrhunsku uslugu</b>, stručnost i pažnju koju zaslužujete. Naš cilj
              je da vam pružimo osećaj <b>lepote</b> i blagostanja, i da zajedno sa vama
              stvaramo nezaboravne trenutke u našem <b>salonu</b>. Vaše zadovoljstvo je
              naša najveća nagrada!</p>
          </div>

          {/* Footer right */}
          <div className="footer-right">

            {/* Working hours */}
            <div>
              <div className="footer-column-header">Radno vreme</div>
              <div className="footer-column-text">
                <span>Ponedeljak - Subota</span><span>09.00h - 20.00h</span>
              </div>
            </div>

            {/* Contact us */}
            <div>
              <div className="footer-column-header">Zakazivanje i informacije</div>
              <div className="footer-column-text"><span>Telefon:</span><a className="text-underline"
                                                                          href="tel:+381641244747">+381 64 1244
                747</a>
              </div>

              <div className="footer-column-text"><span>Email:</span><a className="text-underline"
                                                                           href="mailto:beauty.ambassade.salon@gmail.com">
                beauty.ambassade.salon@gmail.com</a>
              </div>
              <div className="footer-column-text text-right"><span>Adresa:</span><a
                className="text-underline"
                href="https://maps.app.goo.gl/dpqbwxqnBttdakrYA" target="_blank">Magelanova 1</a>
              </div>
              <div className="footer-column-text text-right"><span> </span><span>11000 Beograd</span>
              </div>

              {/* Social links */}
              <div className="social-links">
                <a href="https://www.instagram.com/beauty.ambassade.salon/" target="_blank"
                   aria-label="Instagram link">
                  <StaticImage className="social-image" src="../assets/images/icons/instagram-logo.png" alt=""/>
                </a>
                <a href="https://maps.app.goo.gl/dpqbwxqnBttdakrYA" target="_blank"
                   aria-label="Google maps link">
                  <StaticImage className="social-image" src="../assets/images/icons/pin-icon.png" alt=""/>
                </a>
              </div>

            </div>

          </div>

        </div>

        {/* Footer copyright */}
        <p className="copyright-text">©{currentYear} BeautyAmbasade All Rights Reserved</p>

      </div>

    </footer>
  );
};

export default Footer;
